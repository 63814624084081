import Swiper from "swiper";
import '../Slider/assets/style.scss';



let interleaveOffset = 0.5;


window.sliderBanner = function sliderBanner(container = 'swiper-container', delay = 5000) {
    let _delay= {
        delay: delay,
        disableOnInteraction: false,
    };
    if (!delay){
        _delay=false;
    }
    return new Swiper('.' + container, {
        loop: true,
        speed:1000,
        autoplay:_delay,
        noSwiping:true,
        touchRatio:0,
        watchSlidesProgress: true,
        navigation: {
            nextEl: '.banner-next',
            prevEl: '.banner-prev',
        },
        thumbs: {
            swiper: galleryThumbs()
        },
        on: {
            init: function(){
                this.autoplay.stop();
            },
            imagesReady: function(){
                this.el.classList.remove('loading');
                this.autoplay.start();
            },
            slideChangeTransitionEnd: function(){
                let swiper = this,
                        captions = swiper.el.querySelectorAll('.caption');
                    for (let i = 0; i < captions.length; ++i) {
                        captions[i].classList.remove('show');
                    }
                    swiper.slides[swiper.activeIndex].querySelector('.caption').classList.add('show');
                },
                progress: function(){
                    let swiper = this;
                    for (let i = 0; i < swiper.slides.length; i++) {
                        let slideProgress = swiper.slides[i].progress,
                            innerOffset = swiper.width * interleaveOffset,
                        innerTranslate = slideProgress * innerOffset;

                    swiper.slides[i].querySelector(".slide-bgimg").style.transform =
                        "translateX(" + innerTranslate + "px)";
                }
            },
            touchStart: function() {
                let swiper = this;
                for (let i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = "";
                }
            },
            setTransition: function(speed) {
                let swiper = this;
                for (let i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = speed + "ms";
                    swiper.slides[i].querySelector(".slide-bgimg").style.transition =
                        speed + "ms";
                }
            }
        }
    });
};


window.galleryThumbs  = function galleryThumbs (container = 'gallery-thumbs') {
    return new Swiper('.' + container, {
        spaceBetween: 0,
        slidesPerView: 7.5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
};


/**
 *
 * @param container
 * @param viewM
 * @param ratio
 * @param viewT
 * @param viewT2
 * @param viewPc
 * @param ratioPc
 * @param effect
 * @param loop
 * @param free
 * @param delay
 * @param parallax
 * @returns {Swiper}
 */



window.slider = function slider(container = 'swiper-container', viewM= 1.3 ,
                                ratio=1,
                                viewT=2.3,
                                viewT2=2.5,
                                viewPc=3,
                                ratioPc=0,
                                effect= 'slide',
                                loop=true,
                                free=true,
                                delay=50000,
                                parallax=false) {
    let _delay= {
        delay: delay,
        disableOnInteraction: false,
    };
    if (!delay){
        _delay=false;
    }
    return new Swiper('.' + container, {
        slidesPerView:viewM,
        spaceBetween:0,
        effect:effect,
        parallax: true,
        loop:loop,
        freeMode: free,
        noSwiping:true,
        parallaxEl: {
            el: '.parallax-bg',
            value: '-23%'
        },

        touchRatio: ratio,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable:true
        },
        autoplay: _delay,
        breakpoints: {
            575: {
                slidesPerView:viewT
            },
            768: {
                slidesPerView:viewT2
            },
            900: {
                slidesPerView:viewPc ,
                touchRatio: ratioPc
            },
        },
    });
};

window.sliderReferences = function sliderReferences(container = 'swiper-references') {
    return new Swiper('.' + container, {
        slidesPerView:1,
        centeredSlides:true,
        spaceBetween:15,
        loop:true,
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.reference-next',
            prevEl: '.reference-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable:true,
            dynamicBullets:true
        },
    });
};




